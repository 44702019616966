// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from '../environmentConfig';

export const config: EnvironmentConfig = {
  production: false,
  kknowContentApi: 'https://api.dev.cosmose.cloud/kaikai-now-content-api/v1',
  kknowTargetingApi: 'https://targeting.kaikai-now.dev.cosmose.cloud/v1',
  apiRoot: 'https://auth-server.dev.cosmose.cloud/uaa',
  mindBrushApi: 'https://mindbrush-pub.dev.cosmose.cloud',
  kknowAdminApi: 'https://kaikai-now-admin-pub.dev.cosmose.cloud',
  apiKey: 'ZGVhbC1odW50ZXItcGFydG5lci13ZWI6JjZkZVZuRW1ARV4lJFBLQg==',
  prefix: 'Bearer',
};
