// :cow: Cosmose CONFIDENTIAL :iso:
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DefaultValuePipe } from './defaultValue.pipe';


@NgModule({
  declarations: [
    DefaultValuePipe,
  ],
  exports: [
    DefaultValuePipe,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    DecimalPipe,
  ],
})
export class DefaultValueModule {
}
