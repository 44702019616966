// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { LocalStorageKey } from '@par/app/core/model/enums/local-storage-key';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { DecodedToken } from '@par/app/core/model/decoded-token';

@Injectable({
  providedIn: 'root',
})
export class KaiStorageService {
  private roleId$ = new ReplaySubject<string>();
  private regionId$ = new ReplaySubject<string>();
  private partnerId$ = new ReplaySubject<number>();
  private accessToken$ = new ReplaySubject<string>();
  private refreshToken$ = new ReplaySubject<string>();

  getRegionId(): Observable<string> {
    return this.regionId$.asObservable();
  }

  getPartnerId(): Observable<number> {
    return this.partnerId$.asObservable();
  }

  setAccessToken(token: string): void {
    this.accessToken$.next(token);
    localStorage.setItem(LocalStorageKey.accessToken, token);
  }

  setRefreshToken(token: string): void {
    this.refreshToken$.next(token);
    localStorage.setItem(LocalStorageKey.refreshToken, token);
  }

  getAccessToken(): Observable<string> {
    return this.accessToken$.asObservable();
  }

  getAccessTokenValue(): string {
    return localStorage.getItem(LocalStorageKey.accessToken);
  }

  getDecodedAccessToken(): Observable<DecodedToken> {
    return this.accessToken$.asObservable().pipe(map((token) => new JwtHelperService().decodeToken(token)));
  }

  getUsernameFromToken(): Observable<string> {
    return this.getDecodedAccessToken().pipe(map(token => {
      if (!token) {
        return '';
      }
      return token.user_name;
    }));
  }

  getRefreshTokenValue(): string {
    return localStorage.getItem(LocalStorageKey.refreshToken);
  }

  initStorage(): void {
    const roleId = localStorage.getItem(LocalStorageKey.roleId);
    this.roleId$.next(roleId);
    const regionId = localStorage.getItem(LocalStorageKey.regionId);
    this.regionId$.next(regionId);
    const partnerId = +localStorage.getItem(LocalStorageKey.partnerId);
    this.partnerId$.next(partnerId);

    const accessToken = localStorage.getItem(LocalStorageKey.accessToken);
    this.accessToken$.next(accessToken);
    const refreshToken = localStorage.getItem(LocalStorageKey.refreshToken);
    this.refreshToken$.next(refreshToken);
  }

  clearStorage(): void {
    localStorage.removeItem(LocalStorageKey.roleId);
    this.roleId$.next(undefined);
    localStorage.removeItem(LocalStorageKey.regionId);
    this.regionId$.next(undefined);
    localStorage.removeItem(LocalStorageKey.partnerId);
    this.partnerId$.next(undefined);

    localStorage.removeItem(LocalStorageKey.accessToken);
    this.accessToken$.next(undefined);
    localStorage.removeItem(LocalStorageKey.refreshToken);
    this.refreshToken$.next(undefined);
  }
}
