// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { KaiStorageService } from '@par/app/core/auth-services/kai-storage.service';
import { PreviousUrlService } from '@par/app/core/services/previous-url.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class KaiLogoutService {

  constructor(
    private kaiStorageService: KaiStorageService,
    private previousUrlService: PreviousUrlService,
    private router: Router,
  ) {
  }

  logout(): void {
    this.kaiStorageService.clearStorage();
    this.previousUrlService.clear();
    this.router.navigate(['/login']);
  }
}

