// :cow: Cosmose CONFIDENTIAL :iso:
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/core/services/config.injection-token';
import { environment } from './environments/environment';
import { getConfig } from './app/core/config/get-config';

const config = getConfig(document.location.host);
Object.freeze(config);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([{provide: APP_CONFIG, useValue: config}])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
