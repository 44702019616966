// :cow: Cosmose CONFIDENTIAL :iso:
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdobeIntegrationComponent } from '@par/app/shared/adobe-integration-host/components/adobe-integration/adobe-integration.component';
import { DefaultValueModule } from '@par/app/shared/pipes/default-value/default-value.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AdobeIntegrationWrapperComponent } from './adobe-integration-wrapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AdobeIntegrationComponent,
    AdobeIntegrationWrapperComponent,
  ],
  imports: [
    CommonModule,
    DefaultValueModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [
    AdobeIntegrationComponent,
    AdobeIntegrationWrapperComponent,
  ],
})
export class AdobeIntegrationWrapperModule {
}
