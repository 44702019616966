// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from './environmentConfig';
import { config as defaultConfig } from './configs/default';
import { config as adminProd } from './configs/admin.kaik.ai';
import { config as adminPreprod } from './configs/admin-preprod.kaik.ai';
import { config as adminDev } from './configs/admin-dev.kaik.ai';

export const getConfig = (domain: string): EnvironmentConfig => {
  const configMap = {
    'localhost:4200': defaultConfig,
    'kaikai-now-portal.dev.cosmose.cloud': adminDev,
    'admin-dev.kaik.ai': adminDev,
    'kaikai-now-portal.preprod.cosmose.cloud': adminPreprod,
    'admin.kaik.ai': adminProd,
  };

  return configMap[domain] ?? defaultConfig;
};

