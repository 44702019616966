// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from '../environmentConfig';

export const config: EnvironmentConfig = {
  production: true,
  kknowContentApi: 'https://kaikai-now-admin-preprod.sg.cosmose.co/kaikai-now-content-api/v1',
  kknowTargetingApi: 'https://targeting.kaikai-now.dev.cosmose.cloud/v1',
  apiRoot: 'https://auth-server-preprod.sg.cosmose.co/uaa',
  mindBrushApi: 'https://mindbrush-pub.dev.cosmose.cloud',
  kknowAdminApi: 'https://kaikai-now-admin-preprod.sg.cosmose.co',
  apiKey: 'a2Fpa2FpLW5vdy1hZG1pbjpyaXcwMU15ckd6R0JuMDg=',
  prefix: 'Bearer',
};
